import _ from 'lodash'
import gsap from 'gsap'
import verge from 'verge'
export default class PxTexture{
    constructor(el){
        this.el = el
        this.scrollPos = 0
        this.speed = this.el.dataset.speed == "" ? 1 : this.el.dataset.speed
    }
    init(){
        window.addEventListener('scroll', _.debounce(this.move.bind(this)))
    }
    move(){
        const windowScroll = window.scrollY
        if (windowScroll > this.scrollPos) {
            gsap.to(this.el,{
                y: `-=${this.speed}`
            })
        } else {
            gsap.to(this.el,{
                y: `+=${this.speed}`
            })            
        }
        this.scrollPos = windowScroll
    }
}