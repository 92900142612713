import {map, qs,qsa} from '../utils/lib'

export default class BDownload {
    constructor(el) {
        // this.el ==> <a href="" class="b__download-button">
        this.el = el 
        this.pop_up = qs(".b__download-fixed-wrapper")
        this.close_button = qs(".close_icon", this.pop_up)
        this.body = qs('body')

    }
    init() {
        this.el.addEventListener("click", ()=>{
            this.pop_up.classList.add("b__download-fixed-wrapper--active")
            
            
        })

        this.close_button.addEventListener("click", ()=>{
            this.pop_up.classList.remove("b__download-fixed-wrapper--active")
            
        })

    }

}