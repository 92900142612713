export default function() {
    const st = document.querySelector('.sticky_timeline')
    const coo = st.getBoundingClientRect()
    const bottom = coo.bottom + st.ownerDocument.defaultView.pageYOffset
    const years = document.querySelectorAll('.js_years')
    const s_years = document.querySelectorAll('.js_sticky_years')
    const width = years[0].offsetWidth
    s_years.forEach((el) => {
        el.style.width = `${width}px`
    })
    scroll()
    window.addEventListener("scroll", () => {
        scroll()
    })
    function scroll(){
        if ( window.scrollY + window.innerHeight > bottom ) {
            st.classList.add("fixed")
        } else {
            st.classList.remove("fixed")
        }
        years.forEach((el,i) => {
            if ( i > 0) {
                const alt_el = s_years[i - 1]
                const pos = el.getBoundingClientRect()
                const alt_pos = alt_el.getBoundingClientRect()
                if ( pos.bottom < alt_pos.bottom ) {
                    alt_el.style.opacity = 0
                } else {
                    alt_el.style.opacity = 1
                }   
            }
            
        })
    }
}