import { qs,qsa } from '../utils/lib'
import Highway from '@dogstudio/highway/src/highway';
import Inter from './intersection.js'
import PX from './parallax.js';
import gsap from 'gsap';
import HeroMedia from './hero_media';
import ToggleDarkMode from './dark_mode_button';
// Fade
class Fade extends Highway.Transition {
  in({ from, to, done }) {
    const trans = qs('.transitioner')
    // Reset Scroll
    window.scrollTo(0, 0);
    
    // Remove Old View
    from.remove();
    const content = qs('.main_content')
    if ( content.dataset.header == "header_white" ) {
      qs('.logo').classList.add("white")
       qs('.menu_icon').classList.add("white")
  } else {
      qs('.logo').classList.remove("white")
       qs('.menu_icon').classList.remove("white")
  }

  const footer = qs('.footer')
  if (footer) {
    if (window.location.href.includes('limontabylimonta') || window.location.href.includes('be-limonta')) {
      footer.classList.add('display-none')
    } else {
      footer.classList.remove('display-none')
    }

    if ( content.dataset.footer == "black" ) {
      footer.classList.add("bg-black")
      qsa('.c-black', footer).forEach((text) => {
        text.classList.remove('c-black')
        text.classList.add('c-white')
      })  
    } else {
      footer.classList.remove("bg-black")
      qsa('.c-white', footer).forEach((text) => {
        text.classList.remove('c-white')
        text.classList.add('c-black')
      })      
    }
  

  }

  

 
qsa('.js-menu-item').forEach((link) => {
  if (content.dataset.language == link.textContent.toLowerCase()) {
    link.classList.add('c-black')
    link.classList.remove('c-white')
  } else {
    link.classList.add('c-white')
    link.classList.remove('c-black')
  }
})
    


    PX()
    Inter()

    // Animation
    const tl = gsap.timeline()
    tl.to(trans,{
      y: "-100%",
      duration: .5,
      onComplete: () => {
        done()
      }
    }, 'some-label')
    
  }

  out({ from, done, trigger }) {
    const trans = qs('.transitioner')   
    const tl = gsap.timeline() 
    const men = qs('.menu_icon.active')
    if ( men ) {
        men.click()
    }
    tl.to(trans,{
      y: "100%",
      duration: 0
    })
    .to(trans,{
    	y:0,
    	duration:.8,
      	onComplete: () => {
          done()
          ToggleDarkMode()

        }
    })
  }
}

export default Fade;