import { qs, qsa } from '../utils/lib'
const BDarkMode = () => {
    
        const modules = qsa(".module")
        const logo_paths = qsa(".be-logo-svg-path")
        const input = qs(".theme-toggle_switch-pill-input")
        const pins = qsa(".b__pin-svg")
        let isDark = false
        const toggle_btn = document.querySelector('.theme-toggle')
        toggle_btn && toggle_btn.addEventListener('click', function (e) {
            isDark = !isDark
            // input.checked = !input.checked
            if (input.checked === false) {
                input.checked = true
            } else {
                input.checked = false
            }
            // console.log(input.checked)
            if (!isDark) {
                document.querySelector('meta[name="theme-color"]').setAttribute('content',  '#FFFFFF');
            } else {
                document.querySelector('meta[name="theme-color"]').setAttribute('content',  '#2F2F2F');
            }
            
            qs('.b').classList.toggle('dark-theme');
            qs('.theme-toggle').classList.toggle("theme-toggle--white")
            qs(".header").classList.toggle('dark-background')
            qs('.logo').classList.toggle('white')
            qs(".menu_icon").classList.toggle('white')
            pins.forEach((pin) => {
                pin.classList.toggle("dark-mode")
            })
            modules.forEach((module)=> {
                module.classList.toggle("dark-theme")
            })
            logo_paths.forEach((path)=> {
                path.classList.toggle("white")
            })
        })

}
export { BDarkMode }