import gsap from 'gsap-b'
import Draggable from 'gsap/dist/Draggable'
import IntertiaPlugin from 'gsap-b/InertiaPlugin'


gsap.registerPlugin(Draggable, IntertiaPlugin)

const dragInit = () => {
    const img = document.querySelector('.b__inner-level')

    let bounds = {
        x: window.innerWidth,
        y: window.innerHeight
    }

    const drag = () => {
        Draggable.create(img, {
            type: "x,y", edgeResistance: 0.80, bounds: {
                top: 0,
                left: 0,
                width: bounds.x,
                height: bounds.y
            }, lockAxis: true, inertia: true, autoScroll: 1, allowEventDefault: true
        });
    }

    window.addEventListener('resize', () => {
        bounds.x = window.innerWidth
        bounds.y = window.innerHeight
        drag()
    })

    drag()
}




export { dragInit }
