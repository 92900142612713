import { gsap } from 'gsap'
import { qs, qsa } from '../utils/lib'


export default function() {
    const elements = qsa('[data-parallax]')
    elements.forEach(el => {
      elements.forEach((el) => {
            gsap.set(el, { scale: 1.15 })
      })
      el.observer = new IntersectionObserver(
        entry => {
          if (entry[0].intersectionRatio > 0) {
            gsap.ticker.add(el.progressTween); 
          } else {
            gsap.ticker.remove(el.progressTween);  
          }
        }
      );
       
      el.progressTween = () => {
        const top = el.getBoundingClientRect().top
        gsap.to(el, {
                    y: -  (top / 15),
                    duration: 0
                })
      }
      el.observer.observe(el);
    });
  

   
     
}