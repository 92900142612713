import gsap from 'gsap'
import {qs,qsa} from '../utils/lib'
import _ from 'lodash'
import verge from 'verge'
export default class IconsMarquee{
    constructor(el){
        this.el = el
        this.scrollPos = 0
    }
    init(){
        window.addEventListener('scroll', _.debounce(this.move.bind(this)), 300)
    }
    move(){
        const windowScroll = window.scrollY
        if (verge.inViewport(this.el)) {
            if (this.scrollPos > windowScroll) {
                gsap.to(this.el,{
                    x: `-=5px`,
                    duration:.2
                })
            } else {
                gsap.to(this.el,{
                    x: `+=5px`,
                    duration:.2
                })
            }
        }
        this.scrollPos = windowScroll
    }
}