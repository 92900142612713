export default [{
	"title": "Scopri Altera",
	"video": "/videos/Altera.mp4",
	"nav"  : ["Altera"]
},{
	"title": "World",
	"video": "/videos/01.mp4",
	"nav"  : ["History","Divisions","Group"]
},{
	"title": "Creation",
	"video": "/videos/02.mp4",
	"nav"  : ["Production","R&D","Integration"]
},{
	"title": "Care",
	"video": "/videos/03.mp4",
	"nav"  : ["Custom made","Projects","Work with us"]
},{
	"title": "Soul",
	"video": "/videos/04.mp4",
	"nav"  : ["Sustainability","Social Responsability","Upcycling"]
},{
	"title": "Stories",
	"video": "/videos/05.mp4",
	"nav"  : ["LIMONTABYLIMONTA","News"]	
}]