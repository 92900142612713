import { dragInit } from '../components/dragInit';
import { qs, qsa } from '../utils/lib'
import gsap from 'gsap'
import { initial } from 'lodash';

const BIntro = () => {

    const visited = localStorage.getItem('visited');
    const pin_layer = qs(".b__clickable")
    const img_layer = qs(".img_layer")
    const intro_layer = qs(".b_intro")
    const intro_inner = qs(".b_intro__inner")


    const anim =() => {
        const tl = gsap.timeline()
            tl.to(img_layer, {
                y: "0%", 
                x: "0%",
                ease: "cubic-bezier(0.79,0.14,0.15,0.86);",
                duration: 1.3,
            }, "t")
            tl.to(pin_layer, {
                y: "0%", 
                x: "0%",
                ease: "cubic-bezier(0.79,0.14,0.15,0.86);",
                duration: 1.3,
            }, "t")
            tl.to(intro_inner, {
                autoAlpha: 0, 
                duration: 0.2,
                delay: -0.4, 
                ease: "cubic-bezier(0.79,0.14,0.15,0.86);", 
            })
            tl.to(intro_layer, {
            autoAlpha: 0, 
            duration: 0.5,
            delay: -0.2,
            ease: "cubic-bezier(0.79,0.14,0.15,0.86);", 
            onComplete: () =>  {
                dragInit()
                intro_layer.classList.add('display-none')
            }
           })
    }

    // if (!visited) {
        
        intro_layer.classList.remove('display-none')
        gsap.set(img_layer, {
            y: "-15%", 
            x: "-15%",
            duration: 0
        })
        gsap.set(pin_layer, {
            y: "-15%", 
            x: "-15%",
            duration: 0
        })
        gsap.set(intro_layer, {
            autoAlpha: 1,
            duration: 0
        })
        intro_layer.addEventListener("click", (e)=>{
            anim()
        })
        

        if (window.innerWidth < 960 ) {
            intro_layer.addEventListener("touchstart", (e)=>{
                console.log("halo")
                anim()
            })
            
        }

    //     localStorage.setItem('visited', true);
    // } else {
    //     dragInit()
    // }
    
    
    
}

export { BIntro }
