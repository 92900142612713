import gsap from 'gsap';

export default function() {
  const images = Array.from(document.querySelectorAll('.animated'))
	const itemDelay = .15;
	images.forEach(function(img) {
	    	gsap.to(img,{
	    		autoAlpha: 0,
			    y: 40,
	    		duration: 0
	    })
	})
    function handleIntersection(entries) {
        let itemLoad = 0;
       entries.map((entry) => {
           if (entry.isIntersecting) {
             if ( entry.target.classList.contains('animated_number')) {
              const numb = entry.target.querySelector('h1')
              const n = numb.dataset.target.replace(",","")
              const round = numb.dataset.target.includes(".")
              const tw = {}
              tw.start = 0
              gsap.to(entry.target,{
                autoAlpha: 1,
                y: 0,
                duration: 1,
                delay: itemLoad*itemDelay
              })
              gsap.to(tw,{
                start: n,
                ease: "power4.out",
                delay: itemLoad*itemDelay,
                duration: 1.5,
                onUpdate: () => {
                  numb.innerHTML = round ? (Math.round(tw.start * 100) / 100).toLocaleString('us') :  Math.round(tw.start).toLocaleString('us')
                }
              })
             } else {
              gsap.to(entry.target,{
                    autoAlpha: 1,
                    y: 0,
                    duration: 1,
                    delay: itemLoad*itemDelay
              })
             }
             
             observer.unobserve(entry.target)
             itemLoad ++
         }
       });
     }
     const observer = new IntersectionObserver(handleIntersection);
     images.forEach(image => observer.observe(image));
}