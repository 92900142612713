import {qs,qsa} from '../utils/lib'
import gsap from 'gsap'
export default class Menu {
    constructor(el){
        this.el = el
        this.icon = qs('.menu_icon')
        this.lines = qsa('.menu_icon_wrapper_line', this.icon)
        this.logo = qs('.logo')
        this.items = qsa(".menu_wrap_inner_row, .menu_left, .menu_right, .menu_center", this.el)
        this.closed = true
        this.header = qs('.header')
    }
    init(){
        this.icon.addEventListener('click', this.toggle.bind(this))
        gsap.to(this.el,{
            duration: 0,
            y: '-100%'
        })
        gsap.to(this.items,{
            y: 16,
            opacity: 0
        })
        this.setLanguageActive()
    }
    setLanguageActive(){
        const content = qs('.main_content')
        qsa('.js-menu-item').forEach((link) => {
            if (content.dataset.language == link.textContent.toLowerCase()) {
              link.classList.add('c-black')
              link.classList.remove('c-white')
            } else {
              link.classList.add('c-white')
              link.classList.remove('c-black')
            }
          })
    }
    toggle(){
        const html = document.querySelector('html')
        const body = document.querySelector('body')
        const theme_toggle = document.querySelector('.theme-toggle')
        const header = qs('.header')
        const list = [html,body]
        if ( this.closed ) {
            this.closed = false
            header.classList.add('point-none')
            // remove theme toggle from header when menu opens in belimonta
            if (theme_toggle) {
                theme_toggle.classList.add("display-none")
            }
            // check for belimonta 22 module
            if (qs(".module--active")) {
                header.classList.remove('opaque-background')
            }
            list.forEach((el) => {
                el.classList.add('hidden')
            })
            this.animate('open')
            this.fillEls('open')
            
        } else {
            this.closed = true
            header.classList.remove('point-none')
           
            list.forEach((el) => {
                el.classList.remove('hidden')
            })
            this.animate('close')
            this.fillEls('close')
        }
    }
    animate(dir){
        this.lines.forEach((l) => {
            if (dir == 'open') {
                l.classList.add('open')
                
            } else {
                l.classList.remove('open')
                
            }

        })
        const tl = gsap.timeline()
        if ( dir == 'open') {
            gsap.to(this.el,{
                duration: 0,
                y: '-100%'
            })
            gsap.to(this.items,{
                y: 16,
                opacity: 0
            })
            tl.to(this.el,{
                y: 0,
                duration: 1,
                ease: "expo.inOut"
            })
            tl.to(this.items,{
                opacity: 1,
                y:0,
                stagger: .1
            })
        } else {
           
            tl.to(this.el,{
                y: "100%",
                duration: 1,
                ease: "expo.inOut"
            })
            tl.to(this.items,{
                opacity: 0,
                y:16,
                duration: 0, 
                onComplete: () => {
                    // reset theme toggle from header when menu closes in belimonta
                    if (qs(".theme-toggle")) {
                        if (window.location.href.includes('be-limonta')) {
                            qs(".theme-toggle").classList.remove("display-none")
                        }
                    }
                     // check for belimonta 22 module
                    if (qs(".module--active")) {
                        qs('.header').classList.add('opaque-background')
                    }
                    
                }
            })
        }
        
    }
    fillEls(dir) {
        const list = [this.icon,this.logo]        
        if (dir == 'open') {
            list.forEach((el) => {
                el.classList.add('active')
            })
        } else {
            list.forEach((el) => {
                el.classList.remove('active')
            })
        }       
    }
}