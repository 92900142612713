import {qs,qsa} from '../utils/lib'
const BeAccordion = () => {
    const plus_btn = qs('.show')
    const minus_btn = qs('.hide')
    const acc_wrapper = qs('.accordion_description')
    const animateAcc = (dir) => {
        if (dir > 0) {
            plus_btn.style.display = 'none'
            minus_btn.style.display = 'block'
            acc_wrapper.style.display = 'block'
        } else {
            plus_btn.style.display = 'block'
            minus_btn.style.display = 'none'
            acc_wrapper.style.display = 'none'
        }
    }
    plus_btn.addEventListener('click', () => {
        animateAcc(1)
    })
    minus_btn.addEventListener('click', () => {
        animateAcc(-1)
    })    
}
export default BeAccordion