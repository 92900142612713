import gsap from 'gsap'
import _ from 'lodash'
import {qs,qsa} from '../utils/lib'
export default class ThreeImagesParallax{
    constructor(el){
        this.el = el
        this.images = qsa('.image_wrapper', this.el)
        this.scrollPos = 0
        this.isReverse = this.el.dataset.reverse
    }
    init(){
        this.images.forEach((el, i) => {
            const x = el.getAttribute('data-x')
            const y = el.getAttribute('data-y')
                gsap.set(el, {
                    x: `${x}%`,
                    marginTop: `${y}%`
                })
        })
        window.addEventListener('scroll', _.debounce(this.animate.bind(this)), 3000)
            
    }
    animate(){
        console.log('scroll')
        this.images.forEach((el) => {
            gsap.to(el, {
                y: ((el.parentNode.getBoundingClientRect().top - window.innerHeight) / 12 ) * el.getAttribute('data-speed')
            })
        })
    }
    
}