import {qs,qsa} from '../utils/lib'
import gsap from 'gsap'
export default class Header{
    constructor(el){
        this.el = el
        this.icon = qs('.menu_icon')
        this.lines = qsa('.menu_icon_wrapper_line')
        this.oldScroll = 0
         
    }
    init(){
        this.icon.addEventListener('click', this.reset.bind(this))
        window.addEventListener('scroll', this.scroll.bind(this))
        this.setColor()
    }

    setColor() {
        const content = qs('.main_content')
        if ( content.dataset.header == "header_white" ) {
            qs('.logo').classList.add("white")
            this.icon.classList.add("white")
        } else {
            qs('.logo').classList.remove("white")
            this.icon.classList.remove("white")
        }
    }

    scroll(){
        if (this.oldScroll > window.scrollY == false) {
            //downscroll
            this.close()
            if (window.scrollY == 0){
                this.reset()          
            }
        } else {
            //upscroll
            this.active()
            if (window.scrollY == 0){
                this.reset()          
            }
        }
        this.oldScroll = window.scrollY;

    }
    reset(){ 
        gsap.to(this.el,{
            y: '0%',
            duration: .4
        })
        // dark mode belimonta
        if (qs(".b")) {
            return
        } else {
            this.el.classList.remove("fixed")
        }
        
                  
    }
    active(){
        gsap.to(this.el,{
            y: '0%',
            duration: .4
        })
        // dark mode belimonta
        if (qs(".b")) {
            return
        } else {
            this.el.classList.add("fixed")
        }
        
       
    }
    close(){
        gsap.to(this.el,{
            y: '-100%',
            duration: .4
        })
        // dark mode belimonta
        if (qs(".b")) {
            return
        } else {
            this.el.classList.remove("fixed")
        }
       
    }
}