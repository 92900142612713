import {qs,qsa} from '../utils/lib'
import gsap from 'gsap'
export default class Video{
    constructor(el){
        this.el = el
        this.video_wrapper = qs('.div_video_player_source', this.el)
        this.video = this.video_wrapper.querySelector('video')
        this.index_video = qsa('.div_video_aside_bottom_wrapper', this.el)
        this.qty_video = this.index_video.length
        this.prev = qs('.div_video_aside_bottom_arrows_prev')
        this.next = qs('.div_video_aside_bottom_arrows_next')
        this.active = 0
        this.animating = false
        this.dir = 0
    }
    init(){
        this.index_video.forEach((v,i) => {
            v.addEventListener('click', this.setVideoSrc.bind(this, v,i))
            if (i == 0) {
                v.classList.add('active')
            }
        })
        if (this.animating == true) {
            return
        } else {
            this.prev.addEventListener('click', this.setActive.bind(this,-1))
            this.next.addEventListener('click', this.setActive.bind(this,1))
        }

    }
    setActive(act){
        this.dir = act
        this.active += act
        if (this.active == this.qty_video) {
            this.active = 0
        } else if (this.active == - 1) {
            this.active = this.qty_video - 1
        }
        this.setVideoSrc(this.index_video[this.active], this.active)
    }
    setVideoSrc(el,index){
        const new_src = el.querySelector('.div_video_aside_bottom_item').getAttribute('data-video')
        this.active = index
        this.video.src = new_src
        this.animate()
    }
    animate(){
        this.animating = true
        this.fadeOut()
        this.index_video.forEach((v,i) => {
            if (i == this.active) {
                v.classList.add('active')
            } else {
                v.classList.remove('active')               
            }
        }) 
    }
    fadeOut(){
        gsap.to(this.video_wrapper,{
            y: `${this.dir * 16}px`,
            opacity: 0,
            onComplete: () => {
                this.fadeIn()
            }
        })
    }
    fadeIn(){
        gsap.fromTo(this.video_wrapper,{
            y: `${-(this.dir) * 16}px`
        },{
            y: 0,
            opacity: 1,
            duration: .4,
            onComplete: () => {
                this.animating = false
            }
        })
    }
}