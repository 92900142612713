
import gsap from "gsap";
import { qs,qsa } from "../utils/lib";
export default function HeroMedia(hero_media_c) { 
    const prev = qs('.prev',hero_media_c) 
    const next = qs('.next',hero_media_c)
    let width = qs('.hero_media_item').offsetWidth
    const slides = qsa('.hero_media_item')
    let active = 0
    const length = qsa('.image',hero_media_c).length
    const duration = .3
    let current_video;

    const video = qs('video',slides[active])
    if ( video ) {
        current_video = video
        current_video.play()
    }
    window.addEventListener("resize", () => {
        width = qs('.image').offsetWidth
        Animate(active)
        changeSrc()
    })
    next && next.addEventListener('click', () => {

        if (active == length - 1) {
            active = 0
            Animate(active,1)
        } else {
            active += 1
            Animate(active,1)
        }
        
    })   
    prev && prev.addEventListener('click', () => {

        if (active == 0) {
            active = length - 1
            Animate(active,-1)
        } else {
            active -= 1 
            Animate(active,-1)
        }
        
    })
    const Animate = (active,dir) => {
        if ( current_video ) {
            current_video.pause()
        }
        const tl = gsap.timeline()
        tl.to(qsa('.hero_media_item'),{
            skewX: -10,
            ease: "Power1.easeInOut",
            x: `-${(width * active)}`,
            duration: duration
        })
        .to(qsa('.hero_media_item'),{
            skewX: 0,
            ease: "Power1.easeInOut" ,
            duration: duration         
        })   
        const new_video = qs('video',slides[active])
        if ( new_video ) {
            current_video = new_video
            current_video.play()
        }  
    }
    const changeSrc = () => {
        if (window.innerWidth < 641) {
            const videos = qsa('video', hero_media_c)
            videos.forEach((video) => {
                const source = video.querySelector('source')
                source.setAttribute('src', video.dataset.src)
                video.load() 
            })
        } 
    }
    changeSrc()   

}