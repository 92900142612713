import {isTouchDevice, qs,qsa} from '../utils/lib'
import gsap from 'gsap'
export default class ModuleHeader{
    constructor(el){
        this.el = el
        this.oldScroll = 0
        this.var = ""
    }
    
    init(){ 
        window.addEventListener('scroll', this.moduleScroll.bind(this))
        
    }
    
    moduleScroll(){
        if (this.oldScroll > window.scrollY == false) {
            //downscroll
            this.mClose()
            // if (window.scrollY == 0){
            //     this.mReset()          
            // }
        } else {
            //upscroll
            this.mActive()
            // if (window.scrollY == 0){
            //     this.mReset()          
            // }
        }
        this.oldScroll = window.scrollY;

    }
    mReset(){
        
        gsap.to(this.el,{
            y: '0%',
            duration: .4
        })          
    }
    mActive(){
       
        gsap.to(this.el,{
            y: '0',
            duration: .4
        })
    }
    mClose(){
        if (window.innerWidth > 640 ) {
            this.var = "-99px"
           } else {
            this.var = "-57px"
           }
        gsap.to(this.el,{
            y: this.var,
            duration: .4
        })
    }
}