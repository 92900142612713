import { qs, qsa } from "../utils/lib"
function ToggleDarkMode() {
    const theme = qs('.theme-toggle')
    if ( theme && !window.location.href.includes('be-limonta') ) {
      theme.classList.add("display-none")
    }
    if ( theme && window.location.href.includes('be-limonta') ) {
      theme.classList.remove("display-none")
    }
}
export default ToggleDarkMode