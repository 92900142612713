import gsap from 'gsap'
import Data from '../data/home.js'
import { isTouchDevice} from "../utils/lib";

export default class HomeCarousel {
    constructor(el){
        this.el = el
        this.next = el.querySelector('.button-next')
        this.prev = el.querySelector('.button-prev')
        this.circle = el.querySelector('.home_carousel_circle')
        this.title = el.querySelector('.home_carousel_circle span')
        this.subnav = el.querySelector('.home_carousel_circle_nav')
        this.slides = el.querySelector('.home_carousel_slides')
        this.open = false
        this.active = 0
        this.current_el = el.querySelector('.home_carousel_current')
        this.data = JSON.parse(this.el.dataset.items)
        this.loaded_videos = []
        this.loader = el.querySelector('.home_carousel_loader')
        this.loader_logo = el.querySelector('.home_carousel_loader_logo')
        this.loader_el = el.querySelector('.loader_el')
        this.pagers = Array.from(el.querySelectorAll('.home_carousel_pager button'))
        this.path = el.querySelector('.path')
        this.isDevice = window.innerWidth < 768
        this.animating = false
        this.sub_menu_is_animating = false
        this.length = 0
        console.log(this.data)
    }

    loadPath() {

        const path = this.path
        const pathLength = path.getTotalLength();
        const speed = 1.5;
        const percent = 1;
        this.title.innerHTML = this.data[this.active].title
        const tl = gsap.timeline()
        tl.to('.path',{
            'stroke-dasharray':pathLength + ' ' + pathLength,
            'stroke-dashoffset':pathLength,
            duration: 0
        });
        tl.to('.path',{
          'stroke-dashoffset': pathLength * (1 - percent),
          duration: 1,
          ease: "power4.inOut"
        })
        tl.to(this.title,{
            opacity: 1,
            duration: .4
        })
    }
    updateLoader() {
        
        this.length = this.loaded_videos.length
        const target = this.loaded_videos.length / this.length * 100
        if ( this.loaded_videos.length < this.length ) {
            gsap.to(this.loader_el,{
                width: target + "%",
                duration: 1,
                ease: "power2.out",
            })
        } else {
            gsap.to(this.loader_el,{
                width: "100%",
                duration: 2,
                ease: "power2.out",
                onComplete: () => {
                    //if ( this.loaded_videos.length == this.length ) {
                        
                        const tl = gsap.timeline()
                        tl.to(this.loader_logo,{
                            opacity:1,
                            duration: .6
                        })
                        tl.to(this.loader,{
                            y:"100%",
                            duration: .6,
                            delay: .4,
                            ease: "power2.inOut"
                        })
                        tl.to(this.loader,{
                            autoAlpha: 0,
                            duration: 0
                        })
                        tl.call(this.loadPath.bind(this));
                    //}
                }
            })
        }
        
    }

    appendVideos(){
        this.data.forEach((v,index) => {
            const div = document.createElement("div"); 
            div.classList = "home_carousel_slide"
            const video = document.createElement("video");
            video.autoplay = true;
            video.playsInline = true;
            video.loop = true;
            video.muted = true;
            const source = document.createElement('source');
            if ( isTouchDevice && window.innerWidth < 1024 ) {
                source.src = v.m_video;
            } else {
                source.src = v.video;
            }            
            div.appendChild(video)
            video.appendChild(source)
            this.slides.appendChild(div)
            
            this.loaded_videos.push('a')
        })
    }

    preload() {
        this.data.forEach((v,index) => {
            const req = new XMLHttpRequest();
            if ( isTouchDevice && window.innerWidth < 1024 ) {
                req.open('GET', v.m_video, true);
            } else {
                req.open('GET', v.video, true);
            }
            req.responseType = 'blob';
            req.onload = (e,s) => {
               // Onload is triggered even on 404
               // so we need to check the status code
               if (req.status === 200) {
                  const videoBlob = req.response;
                  const vid = URL.createObjectURL(videoBlob); // IE10+
                  v.show_video = vid
                  this.loaded_videos.push('a')
                  this.updateLoader()
               }
            }
            req.onerror = function() {
               // Error
               console.log('e')
            }
            req.send();
            console.log(req)
        })
    }
    init() {
        this.el.style.height = window.innerHeight + "px"
        //this.preload()
        this.appendVideos()
        this.updateLoader()
        this.updatePager()
        this.circle.addEventListener("click",() => {
            if (this.sub_menu_is_animating) {
                return false
            } else {
                this.setMenu()
            }

        })
        this.next.addEventListener("click", () => {
            if (this.animating || this.sub_menu_is_animating) {
                return false
            } else {
                this.goNext()
            }
        })
        this.prev.addEventListener("click", () => {
            if (this.animating || this.sub_menu_is_animating) {
                return false
            } else {
                this.goPrev()    
            }
            
        })
        this.el.addEventListener('swiped-left', (e) => {
            if (this.animating || this.sub_menu_is_animating) {
                return false
            } else {
                this.goNext()
            }
        });
        this.el.addEventListener('swiped-right', (e) => {
            if (this.animating || this.sub_menu_is_animating) {
                return false
            } else {
                this.goPrev()
            }
            
        });

      
    }

    setMenu() {
        if ( this.open ) {
            this.closeSub()
        } else {
            this.open = true
            this.openSub()
        }
    }
    goNext() {
        this.animating = true
        let direction = 1
        if ( this.active == this.length - 1 ) {
            this.active = 0
            direction = -1
        } else {
           this.active ++     
        }
        this.animate(direction)
    }

    goPrev() {
        this.animating = true
        let direction = -1
        if ( this.active == 0 ) {
            this.active = this.length - 1
        } else {
            this.active --
            direction = 1
        }
        this.animate(direction)
    }

    updatePager() {
        this.current_el.innerHTML = this.active + 1
        this.pagers.forEach((p,i) => {
            if ( i == this.active ) {
                p.classList.add("active")
            } else {
                p.classList.remove("active")
            }
        })
    }

    animate(direction) {
        const w = this.slides.getBoundingClientRect().width
        this.reset(direction)
        this.updatePager()
        gsap.to(this.slides,{
            x: -(this.active * w),
            ease: "power2.out",
            duration: 1,
            onComplete: () => {
                this.animating = false
            }
        })
    }

    openSub() {
        this.sub_menu_is_animating = true
        this.subnav.style.pointerEvents = "all";
        this.subnav.innerHTML = ''
        let links = []
        this.data[this.active].nav.forEach((el,i) => {
            const a = document.createElement('a');
            a.classList = "h3 thin c-white",
            a.href = el.slug
            if (el.slug.includes('https://')) {
                a.setAttribute("target", "_blank")
            } 
            a.innerHTML = el.title
            this.subnav.appendChild(a)
            links.push(a)
        })
        window.H.attach(links)
        const tl = gsap.timeline()
        tl.to(this.title,{
            opacity: 0,
            duration: .4
        })
        tl.to(this.subnav,{
            opacity: 1,
            duration: 1,
            onComplete: () => {
                this.sub_menu_is_animating = false                
            }
        })
        this.open = true
    }

    closeSub() {
        this.sub_menu_is_animating = true
        this.subnav.style.pointerEvents = "none";
        const tl = gsap.timeline()
        
        tl.to(this.subnav,{
            opacity: 0,
            duration: .4
        })
        tl.to(this.title,{
             opacity: 1,
            duration: 1,
            onComplete: () => {
                this.sub_menu_is_animating = false  
            }
        })
        this.open = false
        
    }
    reset(direction) {
        const tl = gsap.timeline()
        if ( this.open ) {
            this.title.innerHTML = Data[this.active].title
            this.closeSub()
        } else {
             tl.to(this.title,{
                x: -direction * 500,
                opacity: 0,
                duration: .4,
                onComplete: () => {
                    this.title.innerHTML = Data[this.active].title
                }
             })
             tl.to(this.title,{
                 x: direction * 500,
                 duration: 0
             })
              tl.to(this.title,{
                x: 0,
                opacity: 1,
                duration: .6
            })
        }
        

    }
}