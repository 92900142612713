
import { qs, qsa } from '../utils/lib'
const BLottiePlayer = ()  => {
  const lotties = qsa("#animation")
  const options = {
    rootMargin: "0px 0px -200px 0px",
  };
	
    function playAnimation(entries) {
       
       entries.map((entry) => {
           if (entry.isIntersecting) {
             entry.target.play()
             
             observer.unobserve(entry.target)
            
         }
       });
     }
     const observer = new IntersectionObserver(playAnimation, options);
     lotties.forEach(lottie => observer.observe(lottie));
}

export { BLottiePlayer }