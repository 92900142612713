import {qs,qsa} from '../utils/lib'
import gsap from 'gsap'
import _ from 'lodash'
export default class DivSideImage{
    constructor(el){
        this.el = el
        this.layers = qsa('.div_sideimage_wrapper_image', this.el)
        this.images = qsa('.div_sideimage_wrapper_aside_sticky', this.el)
        this.image_container = qs('.div_sideimage_wrapper_aside', this.el)
        this.active = 0
        this.buttons = qsa('.div_i_btn', this.el)
        this.array = []
        this.mobile_images = []
        this.isDevice = window.innerWidth < 768
        this.dots = qsa('.div_i_dot', this.el)
    }
    init(){
        if (!this.isDevice) {
            window.addEventListener('scroll', _.debounce(this.setPosition.bind(this)))
            this.setPosition()
            this.setActive()
            this.dots[0].classList.add('active')
        } else {
            this.setMobileImages(this.buttons[0],0)
        }
         this.buttons.forEach((btn,i) => {
             if (this.isDevice) {
                btn.addEventListener('click', this.setMobileImages.bind(this,btn,i))
             } else {
                btn.addEventListener('click', this.setImages.bind(this,btn,i))
             }
         })
    }
    setPosition(){
        this.layers.forEach((img,i) => {
            const bound_y = img.getBoundingClientRect().y
            if (bound_y <= (img.clientHeight / 2) && bound_y >= -img.clientHeight /2 ){
                this.active = i
                this.setActive()
            }
        })
    }
    setActive(){
        this.images.forEach((img,i) => {
            if (i == this.active) {
                img.classList.add('active')
            } else {
                img.classList.remove('active')
            }
        })
    }
    setImages(el,index){
        const tl = gsap.timeline()
        tl.to(this.image_container,{
            opacity: 0,
            duration: .4,
            ease: "ease",
            onComplete: () => {
                const new_array = el.dataset.images.split('|')
                this.array = new_array
                this.array.forEach((new_src,i) => {
                    this.images[i].src = new_src
                })
            }
        })
        .to(this.image_container,{
            opacity: 1,
            duration: .4,
            ease: "ease"
        })
        this.dots.forEach((dot,i) => {
            if (i == index) {
                dot.classList.add('active')
            } else {
                dot.classList.remove('active')
            }           
        })
    }
    setMobileImages(el,index){
        const mobile_image = el.querySelector('img')
        const new_src = mobile_image.getAttribute('src')
        this.images[this.active].classList.add('active')
        this.images[this.active].src = new_src   
        this.setMobileActive(index)     
    }
    setMobileActive(ind){
        this.buttons.forEach((btn,i) => {
            const img = btn.querySelector('img')
            if (i == ind) {
                img.classList.add('active')
            } else {
                img.classList.remove('active')
            }             
        })
    }
}