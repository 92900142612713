import {qs,qsa} from '../utils/lib'
import verge from 'verge'
export default class ThreeColImages{
    constructor(el){
        this.el = el
        this.slider = qs('.grid-mb-swipe', this.el)
        this.items = qsa('.threecol_images_img', this.slider)
        this.dots_cont = qs('.dots', this.el)
        this.active = 0
        this.dots = []
    }
    init(){
        this.items.forEach((d,i) => {
            const dot = document.createElement('span')
            dot.classList.add('dot')
            this.dots_cont.appendChild(dot)
            i == 0 ? dot.classList.add('active') : ""
            this.dots.push(dot)
        })
        this.slider.addEventListener('scroll', () => {
            this.items.forEach((d,i) => {
                if (verge.inX(d, -window.innerWidth / 2)) {
                    this.active = i
                    this.dots.forEach((dot,i) => {
                        if (i == this.active) {
                            dot.classList.add('active')
                        } else {
                            dot.classList.remove('active')
                        }
                    })

                }
            })
        })
    }
}