import { map, qs, qsa } from '../utils/lib'
import gsap from 'gsap'
import { ScrollTrigger } from "gsap/ScrollTrigger"
// import { BLottiePlayer } from "../components/b_lottiePlayer"

gsap.registerPlugin(ScrollTrigger)
export default class BMap {

    constructor(el) {
        this.el = el
        this.cells = qsa(".b__cell")
        this.modules = qsa(".module")
        this.img = qs(".b__fixed-area")
        this.active_module = null
        this.active_headerLi = null
        this.headerLi = qsa('.module_header button')
        this.header = qs(".header")
        this.close_buttons = qsa(".module_close")
        this.close_wrapper = qs(".module_close-wrapper")
    }
    init() {
        

        this.cells.forEach((cell, i) => {
            cell.addEventListener("click", (e) => this.handleClick(e, cell, i))
        })

        this.headerLi.forEach((el) => {
            el.addEventListener('click', (e) => this.handleClickHeader(e))
        })

        this.close_buttons.forEach((button) => {
            button.addEventListener("click", (e)=> this.closePopUp(e))
        }) 
        this.setElements()
    }

    setElements() {

        const footer = qs('.footer')
        if (footer) {
          if (window.location.href.includes('be-limonta')) {
            footer.classList.add('display-none')
          } else {
            footer.classList.remove('display-none')
          }      
        }        
    }
    setActiveHeaderLi(id) {
        this.headerLi.forEach((li) =>{
            li.classList.remove("module_header_anchor--active")
            if (li.id === id) {
                this.active_headerLi = li
                this.active_headerLi.classList.add("module_header_anchor--active")
            }
        })
    }

    handleClick(e, cell, i) {
   
        this.modules.forEach((module) => {
            if (module.id === cell.id) {
                this.active_module = module
                this.setActiveHeaderLi(cell.id)
                this.active_module.classList.add("module--active")
                this.img.classList.add("b__fixed-area--sentBack")

                gsap.set(this.active_module, {
                    y: "100%",
                    duration: 0
                })
                gsap.to(this.active_module, {
                    y: "0%",
                    duration: 0.6, 
                    ease: "cubic-bezier(0.65,0.05,0.36,1)", 
                    onComplete: () => {
                        this.header.classList.add("opaque-background")
                    }
                })

                // BLottiePlayer()
                

            }
        })

    }

    closePopUp(e) {
        if (this.active_module != null) {
            const tl = gsap.timeline()
            tl.to(qs(".module_close-wrapper", this.active_module), {
                // y: "-100%",
                opacity: 0,
                duration: 0.1, 
            })
            tl.to(this.active_module, {
                y: "-60%",
                duration: 0.7, 
                delay: -0.1,
                ease: "cubic-bezier(0.65,0.05,0.36,1)", 
                onComplete: () => {
                    this.img.classList.remove("b__fixed-area--sentBack")
                    this.active_module.classList.remove("module--active")
                    this.header.classList.remove("opaque-background")
                    gsap.set(this.active_module, {
                        y: "0%",
                        duration: 0
                    })
                    gsap.set(qs(".module_close-wrapper", this.active_module), {
                        // y: "0%",
                        opacity: 1,
                        duration: 0
                    })
                    this.active_module = null
                    this.close_button= null
                }
            })
        }
        
        
    }

    handleClickHeader(e) {
        this.modules.forEach((module) => {
            module.classList.remove("module--active")
            if (module.id === e.target.id) {
                this.active_module = module
                this.setActiveHeaderLi(e.target.id)
                this.active_module.classList.add("module--active")
                window.scrollTo(0,0)
                this.img.classList.add("b__fixed-area--sentBack")
                // BLottiePlayer()
            }
        })

    }


}
