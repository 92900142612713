import gsap from "gsap"
const Pill = (el) => {
    window.addEventListener('resize', () => {
        animatePill(el)
    })
    const animatePill = (el) => {
        if (window.innerWidth < 641) {
            el.classList.add('fixed')    
        } else {
            el.classList.remove('fixed')    
        }
    }
    animatePill(el)
}
export default Pill