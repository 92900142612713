require('intersection-observer');
require('../utils/swipe.js')
require('flickity-imagesloaded');
require ('lottie-interactive');
require("@lottiefiles/lottie-player");
import Video from '../components/video'
import '../../scss/application.scss'
import Menu from '../components/menu.js'
import ThreeColImages from '../components/threecol_images.js'
import HomeCarousel from '../components/home_carousel'
import TL from '../components/timeline.js'
import Inter from '../components/intersection.js'
import Header from '../components/header'
import { qs, qsa } from '../utils/lib'
import Highway from '@dogstudio/highway/src/highway';
import PX from '../components/parallax.js';
import Transition from '../components/transition.js';
import IconsMarquee from '../components/icons_marquee'
import gsap from 'gsap'
import DivSideImage from '../components/side_image'
import PxTexture from '../components/px_texture'
import ThreeImagesParallax from '../components/images_parallax'
import BeAccordion from '../components/be_accordion';
import Flickity from 'flickity';
import Pill from '../components/pill';
import HeroMedia from '../components/hero_media';
import LinkBlanks from '../components/link_blanks'
import { dragInit } from '../components/dragInit';
import ModuleHeader from '../components/module_header'
import BMap from '../components/b_map'
import BDownload from '../components/b_download';
import { BDarkMode } from '../components/b_darkmode';
import { BIntro } from '../components/b_intro';
import ToggleDarkMode from '../components/dark_mode_button';
import { BLottiePlayer } from '../components/b_lottiePlayer';
function App() {
    ToggleDarkMode()
    LinkBlanks()
    const be22 = qs('.b')
    be22 && BLottiePlayer()
    // DARK MODE BE LIMONTA
    be22 && BDarkMode()
    // intro be limonta22
    be22 && BIntro()
    const b_button = qs(".b__download-button")
    if (b_button) {
        const b_download_app = new BDownload(b_button)
        b_download_app.init()
    }
    const module_header = qsa('.module_header')
    if (module_header) {
        const module_header_app = new ModuleHeader(module_header)
        module_header_app.init()
    }

    const b_map = qs('.b__clickable')
    if (b_map) {
        const b_map_app = new BMap(b_map)
        b_map_app.init()
    }
    const acc = qs('.accordion')
    acc && BeAccordion()
    if (window.innerWidth < 641) {
        const pill = qs('.js-pill')
        pill && Pill(pill)
    }
    const hero_media_c = qs('.js-hero-media')
    if (hero_media_c) {
        HeroMedia(hero_media_c)
    }

    const flk_carousel = qs('.carousel')
    if (flk_carousel) {
        var flkty = new Flickity(qs('.main-carousel', flk_carousel));
        const prev = qs('.prev', flk_carousel)
        const next = qs('.next', flk_carousel)
        next && next.addEventListener('click', () => flkty.next())
        prev && prev.addEventListener('click', () => flkty.previous())
    }

   
    const three_img_px_cont = qsa('.js-images-parallax', document)
    three_img_px_cont.forEach((cont) => {
        const three_px_app = new ThreeImagesParallax(cont)
        three_px_app.init()
    })
    const px_texture_img = qsa('[data-texture]', document)
    px_texture_img.forEach((img) => {
        const px_app = new PxTexture(img)
        px_app.init()
    })
    const side_images = qsa('.js-sideimage', document)
    side_images.forEach((s_img) => {
        const s_img_app = new DivSideImage(s_img)
        s_img_app.init()
    })
    const icons = qsa('.js-icons-marquee', document)
    icons.forEach((icon) => {
        const icon_app = new IconsMarquee(icon)
        icon_app.init()
    })
    const sliders = qsa('.threecol_images', document)
    sliders.forEach((slider) => {
        const sliderapp = new ThreeColImages(slider)
        sliderapp.init()
    })
    const hc = qs('.home_carousel')
    if (hc) {
        const home_carousel = new HomeCarousel(hc)
        home_carousel.init()
    }
    const timeline = qs('.timeline')
    if (timeline) {
        TL()
    }
    const video_blocks = qsa('.js-video')
    video_blocks.forEach((v) => {
        const video_app = new Video(v)
        video_app.init()
    })
    const range = qs('.range-source')
    if (range) {
        range.addEventListener("input", (el) => {
            let value = +el.target.min + (el.target.max - el.target.min) * el.target.value / 100
            qs('.range-target').innerHTML = value.toLocaleString('us')
        })
    }
    const svg = qs('.svg_animation')
    if (svg) {
        const path = qs('path', svg)
        const pathLength = path.getTotalLength();
        const speed = 1.5;
        const percent = 1;
        const tl = gsap.timeline()
        tl.to(path, {
            'stroke-dasharray': pathLength + ' ' + pathLength,
            'stroke-dashoffset': pathLength,
            duration: 0
        });
        tl.to(path, {
            'stroke-dashoffset': pathLength * (1 - percent),
            duration: 1,
            ease: "power4.inOut"
        })
        tl.to('.hover_img', {
            opacity: 1
        })
    }
}

document.addEventListener('DOMContentLoaded', () => {
    

    
   

    PX();
    Inter()




    const be = qs('.be')
    if (be) {
        const el = qs('.be_svg')
        const btn = qs('.be_scroll')
        const video = qs('.be_video')
        const tl = gsap.timeline()
        tl.to(el, {
            opacity: 0,
            y: 8,
            duration: 0
        });
        tl.to(btn, {
            opacity: 0,
            y: 8,
            duration: 0
        });
        tl.to(el, {
            opacity: 1,
            y: 0,
            duration: 1,
        })
        tl.to(btn, {
            opacity: 1,
            y: 0,
            duration: 1,
        })


        btn.addEventListener("click", () => {
            window.scrollTo({
                top: window.innerHeight,
                behavior: 'smooth'
            });
        })

    }

    

    const header = qs('.header')
    if (header) {
        const header_app = new Header(header)
        header_app.init()
    }
    
    const menu = qs('.menu')
    if (menu) {
        const menuapp = new Menu(menu)
        menuapp.init()
    }
    App()
    window.H = new Highway.Core({
        transitions: {
            default: Transition
        }
    });
    window.H.on('NAVIGATE_END', ({ from, trigger, location, to }) => {
        App()
    });
})